export const greetingData = [
  // [22, 'Working late'],
  [18, 'Good evening'],
  [12, 'Good afternoon'],
  [0, 'Good morning'],
  // [0, 'Whoa, early bird'],
];

/**
 * Get a greeting based on the current time
 */
export function getTimedGreeting() {
  const hr = new Date().getHours();
  for (const [hour, greeting] of greetingData) {
    if (hr >= hour) {
      return greeting;
    }
  }
}
